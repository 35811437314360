import React, { useState } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';

type SportProps = {
    sport: string
    lienImage: string
    lienPage: string
    textFr: string
    textEn: string
    isFr: boolean
    switchFrEn:()=>void
}

export const SportCard: React.FC<SportProps> = ({ sport, lienImage, lienPage, textFr, textEn, isFr, switchFrEn}) => {

    const [hovered, isHovered] = useState(false);
    

    return (

        <div className="bg-white p-6 rounded-md mb-8 ms:mt-8">
            <section className="container mx-auto px-4 py-4 ms:py-8">
                <div>
                    <h2 className="text-[2.1rem] font-bold mb-4 ml-2">{sport}</h2>
                </div>

                <div className="flex flex-col md:flex-row">
                    <div className="md:w-1/2 flex flex-col md:flex-row items-center justify-between mb-8">
                        <img src={process.env.PUBLIC_URL + `/images/${lienImage}`} alt={'Image équipe de Basket'} className="w-full md:w-auto mb-4 md:mb-0" />
                    </div>
                    <div className="md:w-1/2">
                        <p className="md:ml-8 md:mt-20 mt-2 ms:mt-20 text-gray-800 text-lg leading-relaxed text-justify mb-10">{isFr ? textFr : textEn}</p>

                        <div
                            className='ml-12 ms:ml-[60%] w-3/5 ms:w-2/5'
                            onMouseEnter={() => isHovered(true)}
                            onMouseLeave={() => isHovered(false)}
                        >
                            <NavLink className={`${hovered ? 'bg-secondary' : 'bg-primary'}  text-white py-2 px-4 rounded-md md:ml-40`} to={lienPage}> {isFr ? `Plus d'infos`: `More Info`}</NavLink>
                        </div>
                        <br></br>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default SportCard;


