import SportCard from "../components/SportCard"

interface ScreenProps {
    isFr: boolean
    switchFrEn: () => void;
}

export const Sports: React.FC<ScreenProps> = ({ isFr, switchFrEn }) => {
    return (
        <main>
            <div className="bg-gray-100 p-3 ms:p-8 mt-[2.5rem]">
                <div className="mb-18">
                    <h2 className="text-3xl font-bold mb-4 ml-5">{isFr ? `Sports à Télécom Paris` : `Sports available at Télécom Paris`}</h2>
                </div>

                <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">
                    <h3 className="text-2xl font-bold mb-4 text-justify"> {isFr ? `Respo Sport au Quotidien` : `Sport Manager `}</h3>
                    <p className="text-gray-700 text-lg mb-6 text-justify">
                        {isFr ? `Chaque sport à un membre du BDS responsable. C'est auprès de lui que vous pouvez aller chercher plus d'informations, que vous pouvez vous inscrire à certaines compétitions. N'hésitez pas à aller voir la section organigramme du site pour trouver les Respos de chaque sport.` : `Each sport has a member of the BDS responsible for it. You can approach them to gather more information and register for specific competitions. Feel free to visit the organizational chart section on the website to find the responsible person for each sport.`}

                    </p>
                </div>

                <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">
                    <h3 className="text-2xl font-bold mb-4 text-justify"> {isFr ? `Attention : site incomplet` : `Warning : website is not complete `}</h3>
                    <p className="text-gray-700 text-lg mb-6 text-justify">
                        {isFr ? `Le site est actuellement en refonte. La liste des sports présentée ici n'est pas exhaustive pour l'année 2024-2025 (il manque tennis et pole-dance), et les horaires risquent d'être modifiés. Les respos de chaque sport sont cependants corrects.` : `The website is currently being modified. The following list does not contains every sport that Telecom offers in the year 2024-2025, and the timetables may be wrong. However the managers responsibles of each sports are the correct ones.`}

                    </p>
                </div>

                <SportCard
                    sport={isFr ? `Athlétisme` : `Running`}
                    lienImage="athletisme.png"
                    lienPage="/athletisme"
                    textFr="Tu as envie d'améliorer ton cardio mais tu ne sais pas comment t'y prendre ? Tu veux rejoindre tes potes pour l'Ekiden du Platal mais t'as peur de faire une perf désastreuse ? T'as kiffé le saut en longueur en EPS et t'as envie d'en refaire ? Alors n'hésite plus et rejoins le groupe d'athlétisme de Telecom Paris ! Des séances seront organisées tous les jours pour se préparer au mieux aux événements athlétiques inter écoles."
                    textEn="You want to improve your cardio but don't know how to do it? Do you want to join your friends for the Platal Ekiden, but are afraid of performing poorly? Did you enjoy long jump in PE class and want to do it again? Then don't hesitate and join the Telecom Paris athletics group! Training sessions will be organized every day to prepare for inter-school athletic events."
                    isFr={isFr}
                    switchFrEn={switchFrEn}
                />

                <SportCard
                    sport="Badminton"
                    lienImage="badminton.jpg"
                    lienPage="/badminton"
                    textFr="Pour (re)découvrir ce sport dans une ambiance sympa ou pour la compétition (tenants du titre de la coupe de l'X rien que ça) vous êtes toutes et tous les bienvenus lors des créneaux de badminton !
                Les raquettes et les volants sont fournis si vous n'en avez pas. En cas de questions, vous pouvez contacter Elio Coste ou Marie Vela-Mena, les respos badminton qui se feront une joie de vous répondre. À bientôt sur les terrains !"
                    textEn="To (re)discover this sport in a friendly atmosphere or for competition (holders of the Coupe de l'X title, just that), you are all welcome during the badminton slots!
                    Rackets and shuttlecocks are provided if you don't have them. If you have any questions, you can contact Elio Coste or Marie Vela-Mena, the badminton managers who will be happy to answer you. See you soon on the courts!"
                    isFr={isFr}
                    switchFrEn={switchFrEn}
                />

                <SportCard
                    sport="Basketball"
                    lienImage="basket.jpg"
                    lienPage="/basket"
                    textFr="À ceux qui préfèrent le bruit d'un 'swish' à l'ASMR, ceux qui se prennent pour Steph Curry en tirant du milieu du terrain, ou encore ceux qui se sont entraînés toute leur vie à dunker mais n'y sont jamais parvenus, le basket à Télécom Paris est fait pour vous. Que vous soyez un(e) basketteur/basketteuse du dimanche ou un prospect de la draft 2024, venez vous entraîner les lundis et mercredis de 20h à 22h au gymnase T5/T6 de l'X, et venez briser les chevilles d'autres étudiants les jeudis après-midi lors des matchs universitaires."
                    textEn="For those who prefer the sound of a 'swish' to ASMR, those who think they're Steph Curry shooting from mid-court, or those who have trained their whole lives to dunk but never succeeded, basketball at Télécom Paris is made for you. Whether you're a Sunday basketball player or a prospect for the 2024 draft, come train on Mondays and Wednesdays from 8pm to 10pm at the T5/T6 gymnasium of l'X, and come break the ankles of other students on Thursday afternoons during university matches."
                    isFr={isFr}
                    switchFrEn={switchFrEn}
                />

                <SportCard
                    sport="Boxe"
                    lienImage="boxe.jpg"
                    lienPage="/boxe"
                    textFr="Vous cherchez une activité qui vous permet de vous défouler tout en apprenant une technique de self-défense ? La boxe est faite pour vous ! Que vous soyez débutant ou expérimenté, la boxe offre de nombreux avantages. En plus d'être un excellent moyen de se défouler, la boxe vous permet de développer votre endurance, votre force et votre coordination. Vous apprendrez également une technique de self-défense qui peut être utile dans certaines situations. Enfin, la boxe peut être pratiquée en groupe ou en solo, selon vos préférences. Alors, qu'attendez-vous pour enfiler des gants de boxe et commencer à vous entraîner ? "
                    textEn="Are you looking for an activity that allows you to let off steam while learning a self-defense technique? Boxing is perfect for you! Whether you are a beginner or experienced, boxing offers many benefits. In addition to being an excellent way to blow off steam, boxing allows you to develop your endurance, strength, and coordination. You will also learn a self-defense technique that can be useful in certain situations. Finally, boxing can be practiced in a group or solo, depending on your preferences. So what are you waiting for to put on boxing gloves and start training? "
                    isFr={isFr}
                    switchFrEn={switchFrEn}
                />

                <SportCard
                    sport={isFr ? `Escalade` : `Climbing`}
                    lienImage="Arkose.jpg"
                    lienPage="/escalade"
                    textFr="Que vous soyez débutants-tes ou confirmés-ées, venez découvrir l'escalade en bloc au pan de l'X, petite salle de bloc conviviale, ou profitez de tarifs réduits à Arkose Massy pour grimper en toute liberté. Pensez à ramener vos chaussons si vous en avez !"
                    textEn="Whether you are a beginner or an experienced climber, come and discover bouldering at the pan de l'X, a small and friendly bouldering gym, or take advantage of reduced rates at Arkose Massy to climb freely. Remember to bring your climbing shoes if you have them!"
                    isFr={isFr}
                    switchFrEn={switchFrEn}
                />

                <SportCard
                    sport={isFr ? `Escrime` : `Fencing`}
                    lienImage="escrime.jpg"
                    lienPage="/escrime"
                    textFr="Vous cherchez une activité qui allie la technique, la stratégie et la grâce ?  Cette discipline olympique offre une variété de styles d'armes, dont l'épée, le sabre et le fleuret. Chacun de ces styles a ses propres règles et techniques, ce qui rend l'escrime stimulante et passionnante. En plus de développer votre agilité, votre coordination et votre force, l'escrime vous apprend également la discipline et la concentration. Que vous soyez débutant ou expérimenté, l'escrime est une activité accessible à tous."
                    textEn="Are you looking for an activity that combines technique, strategy, and grace? This Olympic discipline offers a variety of weapon styles, including the épée, the sabre, and the foil. Each of these styles has its own rules and techniques, making fencing a stimulating and exciting activity. In addition to developing your agility, coordination, and strength, fencing also teaches you discipline and concentration. Whether you are a beginner or experienced, fencing is an activity accessible to all."
                    isFr={isFr}
                    switchFrEn={switchFrEn}
                />



                <SportCard
                    sport={isFr ? `Football Féminin` : `Football reserved to women`}
                    lienImage="footfeminin.jpg"
                    lienPage="/football-feminin"
                    textFr="Envie de devenir un maître du ballon, tout en évoluant dans un sport passionnant et polyvalent ? Envie de vous éclater, aussi bien en entraînement qu’en match et en compétition ? Que vous soyez un débutant ou un passionné de football, notre équipe est là pour vous. Rejoignez-nous et laissez notre super coach vous guider vers la victoire. À bientôt sur le terrain !"
                    textEn="If you want to become a master of the ball, while evolving in an exciting and versatile sport, join our team! Whether you're a beginner or a football enthusiast, we're here for you. Come have fun with us during training, matches, and competitions. Let our super coach guide you to victory. See you on the field!"
                    isFr={isFr}
                    switchFrEn={switchFrEn}
                />

                <SportCard
                    sport={isFr ? `Football Masculin` : `Football reserved to men`}
                    lienImage="foot.jpg"
                    lienPage="/football"
                    textFr="Envie de devenir un maître du ballon, tout en évoluant dans un sport passionnant et polyvalent ? Envie de vous éclater, aussi bien en entraînement qu’en match et en compétition ? Que vous soyez un débutant ou un passionné de football, notre équipe est là pour vous. Rejoignez-nous et laissez notre super coach vous guider vers la victoire. À bientôt sur le terrain !"
                    textEn="If you want to become a master of the ball, while evolving in an exciting and versatile sport, join our team! Whether you're a beginner or a football enthusiast, we're here for you. Come have fun with us during training, matches, and competitions. Let our super coach guide you to victory. See you on the field!"
                    isFr={isFr}
                    switchFrEn={switchFrEn}
                />

                <SportCard
                    sport="Handball"
                    lienImage="handball.jpg"
                    lienPage="/handball"
                    textFr="Le hand à Telecom c'est un groupe soudé, avec du jeu pour tous les niveaux. Les débutants peuvent venir aux entraînements découvrir le sport, tandis que les plus expérimentés participent au championnat universitaire, et aux tournois tout au long de l'année."
                    textEn="Handball at Telecom Paris is a united group, with gameplay for all levels. Beginners are welcome to training sessions to discover the sport, while more experienced players participate in the university championship and tournaments throughout the year. Join us and experience the passion of handball with our team!"
                    isFr={isFr}
                    switchFrEn={switchFrEn}
                />

                <SportCard
                    sport="MMA"
                    lienImage="boxe.jpg"
                    lienPage="/MMA"
                    textFr="Envie d'assurer ta défense en toutes situations, sans te limiter à une discipline ? Amateur de sensations fortes ou simplement curieux de découvrir le sport extrême du moment ? Tu as de l'expérience dans un sport de combat et souhaites maîtriser les angles morts aussi bien que Cyril Gane ? La MMA est faite pour toi ! Le cours est évidemment encadré par notre super coach qui garantira un entraînement en toute sécurité."
                    textEn="If you want to be able to defend yourself in any situation, without limiting yourself to one discipline, and if you're a fan of extreme sports or just curious about the latest trend, then MMA is for you! Whether you have experience in a combat sport or not, our super coach will guide you through a safe and exciting training. Learn to master blind spots like Cyril Gane and join us for an unforgettable experience!"
                    isFr={isFr}
                    switchFrEn={switchFrEn}
                />

                <SportCard
                    sport={isFr ? `Musculation` : `Workout`}
                    lienImage="musculation.jpg"
                    lienPage="/musculation"
                    textFr="Après une prépa ou je n'ai pas fait de sport pendant deux ans, quel plaisir de pouvoir aller à la salle de musculation. Cette sensation de douleur dans les muscles pendant les trois jours qui suivent, indescriptible !!! Mais bon, au fil du temps, on sent mieux et on voit les progrès donc on continue. La salle est assez complète et l'ambiance propice au sport, on met la musique et ça part..."
                    textEn="After two years of preparatory classes without doing any sports, it's a pleasure to be able to go to the gym. The feeling of muscle pain in the three days following a workout is indescribable! But with time, we feel better and see progress, so we continue. The gym is quite complete and the atmosphere is conducive to sports. We can put on music and focus on our training."
                    isFr={isFr}
                    switchFrEn={switchFrEn}
                />


                <SportCard
                    sport={isFr ? `Natation` : `Swimming`}
                    lienImage="natation.jpg"
                    lienPage="/natation"
                    textFr="Salut à toi ! Que tu cherche la perf comme Phelps ou que tu veuille juste faire des bulles dans l’eau, la natation t’accueille avec plaisir ! Dans le premier cas, de multiples compétitions t’attendent : Coupe de l’X, Challenge Central Lyon, TOSS ou le Championnat Universitaire, que ce soit en individuel ou en équipe. Il y a même possibilité d’aller à la salle ensemble. Dans le second cas, pas de soucis. Viens juste pour l’ambiance ou te perfectionner, tu verras on mord pas. Hâte de plonger avec toi!"
                    textEn="Hi there! Whether you're looking for performance like Phelps or just want to make bubbles in the water, swimming welcomes you with pleasure! In the first case, multiple competitions await you: Coupe de l'X, Challenge Central Lyon, TOSS or the University Championship, whether in individual or team. There is even the possibility of going to the gym together. In the second case, no worries. Just come for the atmosphere or to improve, you'll see we won't bite. Can't wait to dive with you!"
                    isFr={isFr}
                    switchFrEn={switchFrEn}
                />


                <SportCard
                    sport={isFr ? `Tennis de Table` : `Ping-Pong`}
                    lienImage="pingpong.jpg"
                    lienPage="/tennis-de-table"
                    textFr="Télécom Paris est double-tenante du titre au TOSS, donc n'hésitez pas à venir perpétuer la tradition ! Avec ça vous pourrez vous entrainer au coin-coin, la chance !!!"
                    textEn="Telecom Paris holds the title of TOSS champion, so don't hesitate to come and perpetuate the tradition! With that, you can practice your coin-coin shots, how lucky!"
                    isFr={isFr}
                    switchFrEn={switchFrEn}
                />

                <SportCard
                    sport="Volley"
                    lienImage="volley.png"
                    lienPage="/volley"
                    textFr="Vous cherchez une activité sportive divertissante ? Ne cherchez plus ! Le volley-ball est le choix parfait pour vous. Que vous soyez débutant ou expérimenté, notre équipe accueille tous les niveaux de compétence. En rejoignant notre équipe de volley-ball, vous bénéficierez de nombreux avantages. Tout d'abord, vous pourrez profiter d'un excellent entraînement physique. Le volley-ball est un sport complet qui sollicite tous les muscles du corps, améliorant ainsi votre force, votre endurance et votre agilité."
                    textEn="Are you looking for a fun sports activity? Look no further! Volleyball is the perfect choice for you. Whether you are a beginner or experienced, our team welcomes all skill levels. By joining our volleyball team, you will enjoy many benefits. First and foremost, you can enjoy excellent physical training. Volleyball is a complete sport that engages all the muscles in your body, improving your strength, endurance, and agility."
                    isFr={isFr}
                    switchFrEn={switchFrEn}
                />

                <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">
                    <h3 className="text-2xl font-bold mb-4 text-justify"> {isFr ? `Vous n'avez pas trouvé votre sport ?` : `You didn't find your sport ?`}</h3>
                    <p>
                        {isFr ? (
                            <>
                                Vous avez tout de même la possibilité de faire du sport en dehors du BDS sur le campus. Il existe un partenariat avec le CSX (club de sport de l'X) qui permet aux étudiants de l'IPP de pratiquer une large variété de sports. Moyennant une adhésion au CSX (28€) plus le prix d'inscription de l'activité choisie (30€-120€), vous pourrez pratiquer le sport que vous voulez parmi la sélection disponible{' '}
                                <a href={'https://www.csx-polytechnique.fr/sites/default/files/Tarifs%20adh%C3%A9sion%20CSX%20saison%202023-2024.pdf'} className='text-lg mb-6 text-blue-700 mb-4'>ici</a>. Le BDS peut décider au cas par cas de contribuer financièrement à l'inscription d'un adhérent. Pour cela, adressez-vous à notre Trésorier Axel.
                            </>
                        ) : (
                            <>
                                You still have the possibility to do sports outside of the BDS on campus. There is a partnership with the CSX (X Sports Club) that allows IPP students to practice a wide variety of sports. By paying a CSX membership fee (28€) plus the registration fee for the chosen activity (30€-120€), you can practice the sport of your choice among the available selection{' '}
                                <a href={'https://www.csx-polytechnique.fr/sites/default/files/Tarifs%20adh%C3%A9sion%20CSX%20saison%202023-2024.pdf'} className='text-lg mb-6 text-blue-700 mb-4'>here</a>. The BDS may decide on a case-by-case basis to financially contribute to a member's registration. To do so, please contact our Treasurer Axel.
                            </>
                        )}
                    </p>
                </div>
            </div>
           
        </main>
    )
}