import React, { useState } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';

interface ScreenProps{
  isFr: boolean;
  SwitchFrEn: ()=>void;
}

export const Menu: React.FC<ScreenProps> = ({isFr, SwitchFrEn}) => {

  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      {/*
      <header>
        <nav className="hidden sm:flex items-center justify-between py-4 px-6 bg-primary">
          <div className='w-1/5 flex'>
            <img src={process.env.PUBLIC_URL + "/images/BDS.png"} alt="Logo du BDS de Télécom Paris Tête de Tigre" className="h-16 sm:h-24"></img>
            <img src={process.env.PUBLIC_URL + "/images/logo_telecom_ipparis_rvb_blanc_fond_transpa.png"} alt="Logo du BDS de Télécom Paris Tête de Tigre" className="h-16 sm:h-24"></img>
          </div>
          <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <a href="#" className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page">Home</a>
              </li>
              <li>
                <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">About</a>
              </li>
              <li>
                <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Services</a>
              </li>
              <li>
                <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Pricing</a>
              </li>
              <li>
                <a href="#" className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Contact</a>
              </li>
            </ul>
          </div>
          <div className='w-1/5 flex'>
            <NavLink className="text-tertiary font-medium hover:text-gray-200 mx-6 text-[26px] no-underline" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/'>Accueil</NavLink><br></br>
            <NavLink className="text-tertiary font-medium hover:text-gray-200 mx-6 text-[26px] no-underline" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/sports'>Sports</NavLink><br></br>
            <NavLink className="text-tertiary font-medium hover:text-gray-200 mx-6 text-[26px] no-underline" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/Calendrier'>Calendrier</NavLink><br></br>
            {/* <NavLink className="text-tertiary font-medium hover:text-gray-200 mx-6 text-[26px] no-underline" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/organigramme'>Organigramme</NavLink><br></br> *-/}
      <NavLink className="text-tertiary font-medium hover:text-gray-200 mx-6 text-[26px] no-underline" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/ekiden'>Ekiden</NavLink><br></br>
      {/*<NavLink className="text-tertiary font-medium hover:text-gray-200 mr-6 text-[26px] no-underline" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/Contact'>Contactez-nous</NavLink><br></br>*-/}
    </div >
      <button className="sm:hidden text-tertiary focus:outline-none">

        <svg viewBox="0 0 20 20" fill="currentColor" className="menu w-8 h-8">

        </svg>
      </button>
        </nav >
  </header > */}

      <nav className="bg-primary" >
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between me-auto p-4">
          <div className='sm:w-[340px] w-3/5 flex justify-between'>
            <img src={process.env.PUBLIC_URL + "/images/BDS.png"} alt="Logo du BDS de Télécom Paris Tête de Tigre" className="h-16 sm:h-24"></img>
            <img src={process.env.PUBLIC_URL + "/images/logo_telecom_ipparis_rvb_blanc_fond_transpa.png"} alt="Logo du BDS de Télécom Paris Tête de Tigre" className="h-16 sm:h-24"></img>
          </div>
          <button onClick={SwitchFrEn}>
       <img src={process.env.PUBLIC_URL + `/images/fren.jpeg`} alt="FrEn" className='h-8' />
       </button>

          <button
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={() => {
              setShowMenu(!showMenu)
            }}
          >
            


            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>
          <div className={`${showMenu ? '' : 'hidden'} w-full md:block md:w-auto`}>
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 rounded-lg bg-primary md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-primary">
              <li>
                <NavLink className="text-tertiary font-medium hover:text-gray-200 mx-6 text-[26px] no-underline" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/'>{isFr ? `Accueil` : `Home`}</NavLink><br></br>
              </li>
              <li>
                <NavLink className="text-tertiary font-medium hover:text-gray-200 mx-6 text-[26px] no-underline" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/sports'>Sports</NavLink><br></br>
              </li>
              {/*<li>
             <NavLink className="text-tertiary font-medium hover:text-gray-200 mx-6 text-[26px] no-underline" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/organigramme'>Organigramme</NavLink><br></br> 
              </li>
              */}
              <li>
                <NavLink className="text-tertiary font-medium hover:text-gray-200 mx-6 text-[26px] no-underline" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/Calendrier'>{isFr ? `Calendrier` : `Calendar`}</NavLink><br></br>
              </li>
              <li>
                <NavLink className="text-tertiary font-medium hover:text-gray-200 mx-6 text-[26px] no-underline" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/ekiden'>Ekiden</NavLink><br></br>

              </li>
              <li>
                <NavLink className="text-tertiary font-medium hover:text-gray-200 mx-6 text-[26px] no-underline" style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })} to='/cotisation'>Cotisation</NavLink><br></br>

              </li>
              
            </ul>
            
          </div>
          
        </div>
       
      </nav >
      
    </>
  )
}