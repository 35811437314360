import React, { useState, useEffect } from 'react';


interface ScreenProps {
    isFr: boolean;
    SwitchFrEn: () => void;
}

export const Handball: React.FC<ScreenProps> = ({ isFr, SwitchFrEn }) => {



    return (
        <html>
            <div className="bg-gray-100 opacity-100 p-8 mb-[100px] mt-[50px]">
                <div className="mb-18">
                    <h2 className="text-4xl font-bold mb-8">Handball</h2>
                </div>


                <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">

                    <div className='flex flex-col md:flex-row'>
                        <div className="md:w-1/2 mb-8">
                            <h3 className="text-xl font-bold mb-8 text-justify">{isFr ? `Respo Sport au Quotidien :` : `Sport Manager :`} Benjamin Dupuis</h3>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Infos pratiques` : `General Infos`}</h3>
                            <p className="p text-gray-700 text-lg mb-6 text-justify text-sm">
                                {isFr ? `Entraînement avec coach le lundi, en autonomie le mercredi. ` : `There is a coach on monday, but not of wednesday.`}
                            </p>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Horaires` : `Schedules`}</h3>
                            <div className='mb-4'>
                                <p className="p text-gray-700 mt-2 text-sm whitespace-pre-line">
                                    {isFr ? `Créneaux :` : `Sessions`}<br />
                                    - {isFr ? `Lundi de 17:30 à 19:30 Grand gymnase de l'X` : `Monday from 5:30 to 7:30 PM Great gymnasium of polytechnique`}<br />
                                    - {isFr ? `Mercredi de 20:00 à 22:00 Grand gymnase de l'X` : `Wednseday from 8:00 to 10:00 PM Great gymnasium of polytechnique`}
                                </p>
                            </div>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Se rendre au gymnase :` : `Going to the gymnasium`}</h3>
                            <p className="text-gray-700 text-lg mb-6 text-justify text-sm">{isFr ? `Le grand gymnase de l'X se situe à gauche après la halle des sports quand on arrive depuis Télécom.` : `The Great gymnasium of polytechnique is located on the left after the halle des sports when comming from télécom.`} </p>
                            <a href={'https://goo.gl/maps/BfVHZDUAxWZJ2a3DA'} className=" ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">{isFr ? `Lien Maps` : `Maps Link`}</a>


                        </div>
                        <div className="md:w-1/2 md:ml-20 ml-8 mt-10">
                            <img src={process.env.PUBLIC_URL + `/images/handball.jpg`} alt={'Image équipe de Handball'} className="w-full md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>
                    {/*
                    <div className=''>
                      
                <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Calendrier`:`Calendar`}</h3>
                        <p className="mb-4">{isFr ? `Il peut mettre du temps à charger`:` It can take some time to load`}</p>
                        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FParis&showTitle=0&showPrint=0&showTabs=0&showCalendars=0&showTz=0&mode=WEEK&src=Y181MjVlYmMwMjY2NGQ0NjE4NDQyZGMyNzBlNTAwZTdhODI3ZGQ5MzMwNmYwOGM3YzBkNzQ2YjhjZTBlNDVkMmFmQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23EF6C00" width="600" height="450" className='mb-4 w-[100%]' ></iframe>
                        
                        <a href={'https://calendar.google.com/calendar/u/5?cid=Y181MjVlYmMwMjY2NGQ0NjE4NDQyZGMyNzBlNTAwZTdhODI3ZGQ5MzMwNmYwOGM3YzBkNzQ2YjhjZTBlNDVkMmFmQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20'} className=" ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">{isFr? `Enregistrer le calendrier`:`Save the calendar`}</a>
                </div>
                
   */}
                </div>
            </div>
        </html>
    )

}
