



import React, { useState, useEffect } from 'react';


interface ScreenProps {
    isFr: boolean;
    SwitchFrEn: () => void;
}

export const Musculation: React.FC<ScreenProps> = ({ isFr, SwitchFrEn }) => {



    return (
        <html>
            <div className="bg-gray-100 opacity-100 p-8 mb-[100px] mt-[50px]">
                <div className="mb-18">
                    <h2 className="text-4xl font-bold mb-8">{isFr ? `Musculation` : `Workout`}</h2>
                </div>


                <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">

                    <div className='flex flex-col md:flex-row'>
                        <div className="md:w-1/2 mb-8">
                            <h3 className="text-xl font-bold mb-8 text-justify">{isFr ? `Respo Sport au Quotidien :` : `Sport Manager :`} Axel Guimbault</h3>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Infos pratiques` : `General Informations`}</h3>
                            <p className="p text-gray-700 text-lg mb-6 text-justify text-sm">
                                {isFr ? `Entraînement en autonomie, si besoin demandez des conseils aux 2A pour l'utilisation des machines pour pas se blesser. Pour des informations concernant l'abonnement à la salle du CSX, contacter Hamza El Arji` : `There is no coach thus if you need ask advice to 2A to use the machines in order to not harm yourself. For more informations on the CSX gym, please contact Hamza El Arji`}

                            </p>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Horaires` : `Schedules`}</h3>
                            <div className='mb-4'>
                                <p className="p text-gray-700 mt-2 text-sm whitespace-pre-line">
                                    {isFr ? `Créneaux :` : `Sessions :`}<br />
                                    - {isFr ? `Mardi de 17:00 à 18:30 Halle des Sports de l'X` : `Tuesday from 5:00 PM to 6:30 PM Halle des Sports of polytechnique`}<br />
                                    - {isFr ? `Mercredi de 18:00 à 20:00 Halle des Sports de l'X (reservé femmes - créneau IP Paris)` : `Wednesday from 6:00 PM to 8:00 PM Halle des Sports of polytechnique (women only - IP Paris slot)`}<br />
                                    - {isFr ? `Jeudi de 20:30 à 22:00 Halle des Sports de l'X` : `Thursday from 8:30 PM to 10:00 PM Halle des Sports of polytechnique`}<br />
                                    - {isFr ? `Vendredi de 17:30 à 19:30 Halle des Sports de l'X` : `Friday from 5:30 to 7:30 Halle des sports of polytechnique`}<br />
                                    - {isFr ? `Dimanche de 10:00 à 11:30 Halle des Sports de l'X` : `Sunday from 10:00 AM to 11:30 AM Halle des sports of polytechnique`}
                                </p>
                            </div>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Se rendre au gymnase :` : `Going to the gymnasium`}</h3>
                            <p className="text-gray-700 text-lg mb-6 text-justify text-sm">{isFr ? `La salle de musculation se situe dans la halle des sports de polytechnique après la caserne de pompier.` : `The workout room is located in the halle des sports of polytechnique after the firefighters`}</p>
                            <a href={'https://goo.gl/maps/vT8FZ37x76GJPMbx9'} className=" ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">{isFr ? `Lien Maps` : `Maps Link`}</a>


                        </div>
                        <div className="md:w-1/2 ml-8 mt-10">
                            <img src={process.env.PUBLIC_URL + `/images/musculation.jpg`} alt={'salle de musculation'} className="w-full md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row'>
                        {/*
                <div className="md:w-1/2 mb-8">
                <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Calendrier`:`Calendar`}</h3>
                        <p className="mb-4">{isFr ? `Il peut mettre du temps à charger`:` It can take some time to load`}</p>
                        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FParis&showTitle=0&showPrint=0&showDate=1&showTabs=0&showTz=0&showCalendars=0&mode=WEEK&src=Y184MDVjMmYzZjVhNTY0NGI0ZDlkZGZiOWJlN2U0ZDg0MmU3NTJhYmQ4NzY3Njk4MmIwYjQ2M2JhZjkzMThlMzU1QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23F6BF26" width="600" height="450" className='mb-4 w-[100%]' ></iframe>
                        
                        <a href={'https://calendar.google.com/calendar/u/5?cid=Y184MDVjMmYzZjVhNTY0NGI0ZDlkZGZiOWJlN2U0ZDg0MmU3NTJhYmQ4NzY3Njk4MmIwYjQ2M2JhZjkzMThlMzU1QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20'} className=" ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">Enregistrer le calendrier</a>
                
   </div> */}

                        <div className="md:w-1/2 md:ml-20 mt-20">
                            <p className="text-xl font-bold mb-4 text-justify">{isFr ? `Plan` : `Map`}</p>
                            <img src={process.env.PUBLIC_URL + `/images/salle muscu.png`} alt={'plan maps salle muscu'} className="w-full md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>

                </div>
            </div>
        </html>
    )

}
