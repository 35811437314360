interface ScreenProps{
    isFr: boolean;
    SwitchFrEn: ()=>void;
  }
 
  export const Escrime: React.FC<ScreenProps> = ({isFr, SwitchFrEn}) =>{
    

    
   return (
   <html>
   <div className="bg-gray-100 p-8">
   <div className="mb-18">
       <h2 className="text-4xl font-bold mb-8">{isFr ? `Escrime`:`Fencing`}</h2>
   </div>
   
   
   <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">

                <div className='flex flex-col md:flex-row'>
                <div className="ms:w-1/2 mb-8">
                    <h3 className="text-xl font-bold mb-8 text-justify">{isFr ? `Respos Sport au Quotidien :`:`Sport Managers :`}Quentin Revillon, Thibaud Chasteauneuf
</h3>
                    <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Infos Pratiques`:`General Info`}</h3>
                        <p className="p text-gray-700 text-lg mb-6 text-justify text-sm">
                        {isFr ? `Le BDS ne prend pas en charge l'adhésion à l'escrime. Il faut s'inscrire au près du csx de polytechnique.`:`The BDS does not take in charge the fencing membership. You need to register at the CSX of polytechnique.`} <br />
                        {isFr ? `Le coût annuel est de 30€. Durant l'année, vous pourrez participer au challenge X systra organisé par l'X.`:`The price for 1 year is 30€. During the year you will be able to do the challenge X systra organised by polytechnique.`}
                        </p>
                        <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Horaires`:`Schedules`}</h3>
                    <div className='mb-4'>
                        <p className="p text-gray-700 mt-2 text-sm whitespace-pre-line">
                        {isFr ? `Créneaux :`:``}<br/>
                            - {isFr ? `Jeudi fleuret de 13:30 à 14:30 Salle d'arme de l'X`:`Thursday foil from 1:30 pm to 2:30 pm in the armory room of l'X.`} <br/>
                            - {isFr ? `Jeudi épée de 14:30 à 15:30 Salle d'arme de l'X`:`Thursday épée from 2:30 pm to 3:30 pm in the armory room of l'X.`} <br/>
                            - {isFr ? `Jeudi sabre de 15:30 à 16:30 Salle d'arme de l'X`:`Thursday sabre from 3:30 pm to 4:30 pm in the armory room of l'X.`} <br/>
                            
                        </p>
                    </div>
                    <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Se rendre à la salle d'arme de l'X :`:`Going to the armory place`}</h3>
                    <p className="text-gray-700 text-lg mb-6 text-justify text-sm"></p>
                    <a href={'https://goo.gl/maps/8R1fN8df89PfzaKU9'} className=" ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md mb-8">{isFr ? `Lien Maps`:`Maps Link`}

</a>

                    
                </div>
                    <div className="ms:w-1/2 ml-8 mt-40">
                        <img src={process.env.PUBLIC_URL + `/images/escrime.jpg`} alt={'salle arme'} className="w-full md:w-auto mb-4 md:mb-0"/>
                    </div>
                </div>

          
               
   </div>
   </div>
   </html>
   )

}
    