import React, { useState, useEffect } from 'react';

interface ScreenProps {
    isFr: boolean;
    SwitchFrEn: () => void;
}

export const Boxe: React.FC<ScreenProps> = ({ isFr, SwitchFrEn }) => {



    return (
        <html>
            <div className="bg-gray-100 opacity-100 p-8 mb-[100px] mt-[50px]">
                <div className="mb-18">
                    <h2 className="text-4xl font-bold mb-8">Boxe</h2>
                </div>


                <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">

                    <div className='flex flex-col md:flex-row'>
                        <div className="md:w-1/2 mb-8">
                            <h3 className="text-xl font-bold mb-8 text-justify">{isFr ? `Respo Sport au Quotidien :` : `Sport Manager :`} Jennifer Timani</h3>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Infos Pratiques` : `General Infos`}</h3>
                            <p className="p text-gray-700 text-lg mb-6 text-justify text-sm">
                                {isFr ? `Entraînement de Boxe anglaise encadrée par une coach. ` : `English Boxe trainning with a coach`}
                            </p>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Horaires` : `Schedules`}</h3>
                            <div className='mb-4'>
                                <p className="p text-gray-700 mt-2 text-sm whitespace-pre-line">
                                    {isFr ? `Créneaux :` : `Sessions`}<br />
                                    - {isFr ? `Lundi de 16:00 à 19:00 Halle des Sports de l'X (avec APT).` : `Monday from 4:00 to 7:00 PM Halle des Ports of polytechnique (with APT)`}
                                </p>
                            </div>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Se rendre à la salle de l'X :` : `Going to the place`}</h3>
                            <p className="text-gray-700 text-lg mb-6 text-justify text-sm"> {isFr ? `La salle de boxe se situe au bout de la halle des sports de Polytechnique, au rez-de-chaussée.` : `The Boxe room is located at the end of the halle de sports of polytechnique.`}</p>
                            <a href={'https://goo.gl/maps/LvLv3wehQPE8UA818'} className=" ms:ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">{isFr ? `Lien Maps` : `Maps Link`}</a>


                        </div>
                        <div className="md:w-1/2 ml-8 mt-10">
                            <img src={process.env.PUBLIC_URL + `/images/boxe.jpg`} alt={'deux boxeurs et un arbitre sur un ring'} className="w-full md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row'>
                        {/*
                <div className="md:w-1/2  mb-8">
                        <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Calendrier`:`Calendar`}

</h3>
                        <p className="mb-4">{isFr ? `Il peut mettre du temps à charger`:` It can take some time to load`}</p>
                        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FParis&showNav=1&showTitle=0&showDate=1&showPrint=0&showTabs=0&showCalendars=0&showTz=0&mode=WEEK&src=Y182NTI4NTg1ZjhiMTIyOTNkMGZkOWZlN2Q3MWNmMDhmMmY3YzBkZjQzMzg2NjcwZjEwM2IxNjU3OWYxZjk4YmY1QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23F4511E" width="600" height="450" className='mb-4 w-[100%]' ></iframe>
                        
                        <a href={'https://calendar.google.com/calendar/u/5?cid=Y182NTI4NTg1ZjhiMTIyOTNkMGZkOWZlN2Q3MWNmMDhmMmY3YzBkZjQzMzg2NjcwZjEwM2IxNjU3OWYxZjk4YmY1QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20'} className=" ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">{isFr ? `Enregistrer le calendrier`:`Save the calendar`}</a>
                
   </div>*/}

                        <div className="md:w-1/2 md: ml-20 mt-20">
                            <p className="text-xl font-bold mb-4 text-justify">{isFr ? `Plan` : `Map`}</p>
                            <img src={process.env.PUBLIC_URL + `/images/salle muscu.png`} alt={'plan maps salle muscu'} className="w-full md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>


                </div>
            </div>
        </html>
    )

}
