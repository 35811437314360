interface ScreenProps {
    isFr: boolean;
    SwitchFrEn: () => void;
}

export const FootballFeminin: React.FC<ScreenProps> = ({ isFr, SwitchFrEn }) => {



    return (
        <html>
            <div className="bg-gray-100 opacity-100 p-8 mb-[100px] mt-[50px]">
                <div className="mb-18">
                    <h2 className="text-4xl font-bold mb-8">Football {isFr ? `Féminin` : `Reserved to Women`}</h2>
                </div>


                <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">

                    <div className='flex flex-col md:flex-row'>
                        <div className="md:w-1/2 mb-8">
                            <h3 className="text-xl font-bold mb-8 text-justify">{isFr ? `Respos Sport au Quotidien :` : `Sport Managers :`} Félicité Yvert, Sarah Dos Santos</h3>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Infos pratiques` : `Main Infos`} </h3>
                            <p className="p text-gray-700 text-lg mb-6 text-justify text-sm">
                                {isFr ? `Durant l'année, les matchs sont le jeudi après midi.` : `During the year the matchs are on Thursday afternoon`}
                            </p>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Horaires` : `Schedules`}</h3>
                            <div className='mb-4'>
                                <p className="p text-gray-700 mt-2 text-sm whitespace-pre-line">
                                    {isFr ? `Créneaux :` : `Sessions`}<br />
                                    - {isFr ? `Lundi 19h30-21h30 terrain de foot de l'ENSTA` : `Monday 7:30 PM to 9:30 PM on ENSTA's football pitch`}<br />
                                    - {isFr ? `Jeudi 19h30-21h30 terrain de foor de l'ENSTA (parfois)` : `Thursday 7:30 PM to 9:30 PM on ENSTA's football pitch `}
                                </p>
                            </div>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Se rendre au stade :` : `Going to the football pitch`}</h3>
                            <p className="text-gray-700 text-lg mb-6 text-justify text-sm"> {isFr ? `Les terrains de foot se situe en direction de la tour d'astronomie avant la piste d'athletisme sur la gauche.` : `The pitches are located in direction of the astronomy tower befor the running field`}</p>
                            <a href={'https://goo.gl/maps/kk3NZpWCWrMkAoDb7'} className=" ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">{isFr ? `Lien Maps` : `Maps Link`}</a>


                        </div>
                        <div className="md:w-1/2 ml-8 mt-10">
                            <img src={process.env.PUBLIC_URL + `/images/footfeminin.jpg`} alt={'équipe de foot féminine'} className="w-full md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row'>
                        {/*
                <div className="md:w-1/2 mb-8">
                        <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Calendrier`:`Calendar`}</h3>
                        <p className="mb-4">{isFr ? `Il peut mettre un peu de temps à charger`:`It can take some time to load`}</p>
                        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FParis&showTitle=0&showPrint=0&showCalendars=0&showTabs=0&showTz=0&mode=WEEK&src=Y180YTQzOTY3YzY4ZmNmMTIwNGU1MWUwYmI2YTQ1MjM1MTJhZmFiYzQyNzg5MWUxNzNlNGI1Njg4NmQ1MGFmMjMwQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23AD1457" width="600" height="450" className='mb-4 w-[100%]' ></iframe>
                        
                        <a href={'https://calendar.google.com/calendar/u/5?cid=Y180YTQzOTY3YzY4ZmNmMTIwNGU1MWUwYmI2YTQ1MjM1MTJhZmFiYzQyNzg5MWUxNzNlNGI1Njg4NmQ1MGFmMjMwQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20'} className=" ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">{isFr ? `Enregistrer le calendrier`:`Save the calendar`}</a>
                
   </div>*/}

                        <div className="md:w-1/2 mt-20 md:ml-20">
                            <p className="text-xl font-bold mb-4 text-justify">Plan</p>
                            <img src={process.env.PUBLIC_URL + `/images/stade foot.png`} alt={'plan maps stade foot'} className="w-full md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>


                </div>
            </div>
        </html>
    )

}
