
import React, {useEffect, useState} from 'react'

interface BackendData{
  users: string[];
}

export const Test =() => {

  const[backendData, setBackendData]= useState<BackendData>();

  useEffect(() =>{
    fetch("/api").then(
      response=>response.json()
    ).then(
      data =>{
        setBackendData(data)
      }
    )
  }, [])

    return (
      <main>
        <h2>Web Page</h2>
        <div>
        {(typeof backendData === 'undefined') ? (
        <p>Loading...</p>
      ): (
        backendData.users.map((user,i)=>(
          <p key={i}>{user}</p>
        ))
      )}
      </div>
      
      </main>

    )
}

export default Test;