
import React from 'react';

interface ScreenProps{
    isFr: boolean
    switchFrEn:()=>void
}
export const EkidenPage: React.FC<ScreenProps> = ({isFr, switchFrEn}) => {
  
  

  return (
    <main>
      <div className="flex justify-center h-full w-full bg-cover bg-center py-4 rounded my-[50px]" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/ekiden_photo_groupe.jpg'})` }}>
        <div className=" bg-white bg-opacity-80 shadow-lg ms:px-6 px-3 ms:py-8 py-3 md:p-12 mx-3 ms:mx-auto max-w-3xl rounded-lg ms:mt-16 mt-3">
          <h2 className="text-4xl font-bold mb-4">Ekiden du Platal</h2>
          <div className="bg-white p-6 shadow-md rounded-md mb-8 ms:mt-8">
            <section className="container mx-auto">
              <div>
                <p className=" text-gray-800 leading-relaxed text-justify mb-2 ">{isFr ? ` L'Ekiden du platal est le plus gros évênement du BDS, il s'agit d'un marathon en relais de 6 organisé par le BDS de Télécom Paris. Cet événement, ouvert à toutes les écoles du Plateau de Saclay, et plus largement à tous les étudiants de l’Île de France, a pour objectif de rassembler les participants, de tous niveaux, autour d’un événement sportif et fédérateur.  Sa préparation commence dès le début de l'année, alors si tu veux nous aider à l'organiser hésites pas et contacte au plus vite notre Vice-Président Ekiden Iba.
               `:`The Ekiden of Plateau is the biggest event organized by the BDS, which is a relay marathon of 6. This event is open to all schools in the Plateau de Saclay, and more broadly to all students in Île de France, with the aim of bringing together participants of all levels around a unifying sports event. Its preparation starts at the beginning of the year, so if you want to help us organize it, don't hesitate and contact our Vice-President Ekiden Iba as soon as possible.`}
                  </p>
              </div>
            </section>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between mb-8">
            <img src={process.env.PUBLIC_URL + `/images/ekiden_affiche.jpg`} alt="Affiche de l'Ekiden du Platal" className="w-full md:w-1/3 mb-8 md:mb-0" />
            <div className="md:ml-8">
              <h2 className="text-xl font-bold mb-8"></h2>
              <div className="grid grid-cols-2 gap-4 mb-8">
                <div>
                  <p className="font-bold">Date :</p>
                  <p>{isFr ? `Mai 2025`:`May 2025`}</p>
                </div>
                <div>
                  <p className="font-bold">{isFr ? `Lieu :`:`Place`}</p>
                  <p>Télécom Paris, 91120, Palaiseau</p>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mb-8">
                <div>
                  <p className="font-bold">{isFr ? `Participants :`:`Runners :`}</p>
                  <p>{isFr ? `Pour tous les étudiants d'Île de France et au personnel de l'IP Paris`:`For all students and all workers of the Polytechnique Institute`}</p>
                </div>
                <div>
                  <p className="font-bold">{isFr ? `Prix Télécommien :`:`Price Télécomien`}</p>
                  <p>{isFr ? `Course seule : GRATUIT Course + soirée : prix de la course + 5€ (4€ de réduction sur la prix de la soirée)`:`Race only : FREE run + party : race price + 5€ (-4€ on party )`}</p>
                </div>
              </div>
              <p className="mb-4">{isFr ? `Possibilité de venir déguisé. Prix à la clé !`:`You can wear Costumes. Prizes to win !`}</p>
              <p className="mb-4">{isFr ? `On attend plus de 600 coureurs ! D’ailleurs, les rumeurs courent qu’on organiserait aussi une giga soirée étudiante après la course 🤫.`:`We are expecting 600 runners ! And rumers are that there is a big student party after the race`}</p>
              <a href="https://www.ekiden-platal.fr/" className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded inline-block mt-4">{isFr ? `Lien du site officiel`:`Official Website`}</a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default EkidenPage;