



import React, { useState, useEffect } from 'react';

interface ScreenProps {
    isFr: boolean;
    SwitchFrEn: () => void;
}

export const Badminton: React.FC<ScreenProps> = ({ isFr, SwitchFrEn }) => {



    return (
        <html>
            <div className="bg-gray-100 opacity-100 p-8 mb-[100px] mt-[50px]">
                <div className="mb-18">
                    <h2 className="text-4xl font-bold mb-8">Badminton</h2>
                </div>


                <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">

                    <div className='flex flex-col md:flex-row'>
                        <div className="md:w-1/2 mb-8">
                            <h3 className="text-xl font-bold mb-8 text-justify">{isFr ? `Respo Sport au Quotidiens :` : `Sport Managers :`} Paul Mialane, Antoine Heitzmann</h3>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Infos Pratiques` : `General Info`}</h3>
                            <p className="p text-gray-700 text-lg mb-6 text-justify text-sm">
                                {isFr ? `Entraînement en autonomie.` : `Autonomous training`}
                            </p>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Horaires` : `Schedules`}</h3>
                            <div className='mb-4'>
                                <p className="p text-gray-700 mt-2 text-sm whitespace-pre-line">
                                    {isFr ? `Créneaux :` : `Sessions`}<br />

                                    - {isFr ? `Mercredi de 20:00 à 22:00 Gymnase T5 de l'X` : `Wednesday from 8pm to 10pm at the X's T5 Gymnasium`}
                                    <br />
                                    - {isFr ? `Vendredi de 20:00 à 22:00 Gymnase T5 de l'X` : `Friday from 8pm to 10pm at the X's T5 Gymnasium`}

                                </p>
                            </div>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Se rendre au gymnase :` : `Going to the place`}</h3>
                            <p className="text-gray-700 text-lg mb-6 text-justify text-sm"> {isFr ? `Le gymnase T5 se situe à droite de la halle des sports de Polytechnique quand on arrive depuis Télécom.` : `The place is located on the right of the Halle des sports de polytechnique when you arrive from Télécom`} </p>
                            <a href={'https://goo.gl/maps/MWJyijP1Rg8BPtoT6'} className=" ms:ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">{isFr ? `Lien Maps` : `Maps Link`}</a>


                        </div>
                        <div className="md:w-1/2 ml-8 mt-10">
                            <img src={process.env.PUBLIC_URL + `/images/badminton.jpg`} alt={'filet de Badminton personne qui tire un volant'} className="w-full md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row'>
                        {/*
                <div className="md:w-1/2 mb-8">
                        <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Calendrier`:`Calendar`}</h3>
                        <p className="mb-4">{isFr ? `Il peut mettre du temps à charger`:` It can take some time to load`}</p>
                        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FParis&mode=WEEK&showTitle=0&showPrint=0&showTabs=0&showCalendars=0&showTz=0&src=Y19kNTI5MDhjMjNmMzU4YzQxZDg3NTk0MzJhYmMyOTMwYjUxMGMzMTcyNmIzNTBiNWM3NWRhOGE5ODk3NDM4ZWUzQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23A79B8E" width="600" height="450" className='mb-4 w-[100%]' ></iframe>
                        
                        <a href={'https://calendar.google.com/calendar/u/5?cid=Y19kNTI5MDhjMjNmMzU4YzQxZDg3NTk0MzJhYmMyOTMwYjUxMGMzMTcyNmIzNTBiNWM3NWRhOGE5ODk3NDM4ZWUzQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20'} className=" ms:ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">{isFr ? `Enregistrer le calendrier`:`Save the calender`}</a>
                
   </div>*/}

                        <div className="md:w-1/2 md:ml-20 mt-20">
                            <p className="text-xl font-bold mb-4 text-justify">Plan</p>
                            <img src={process.env.PUBLIC_URL + `/images/salle bad.png`} alt={'plan maps salle badminton'} className="w-full md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>

                </div>
            </div>
        </html>
    )

}
