
interface ScreenProps {
    isFr: boolean;
    SwitchFrEn: () => void;
}

export const Volley: React.FC<ScreenProps> = ({ isFr, SwitchFrEn }) => {



    return (
        <html>
            <div className="bg-gray-100 p-8">
                <div className="mb-18">
                    <h2 className="text-4xl font-bold mb-8">Volley</h2>
                </div>


                <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">

                    <div className='flex flex-col md:flex-row'>
                        <div className="md:w-1/2 mb-8">
                            <h3 className="text-xl font-bold mb-8 text-justify">{isFr ? `Respo Sport au Quotidien masculin:` : `Sport Manager (for men) :`} Martin de Bats
                            </h3>
                            <h3 className="text-xl font-bold mb-8 text-justify">{isFr ? `Respo Sport au Quotidien féminin:` : `Sport Manager (for women) :`} Laura Kubler, Margot Mottais, Anabelle Montaudon, Marion Jan</h3>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Infos pratiques` : `General Infos`}</h3>
                            <p className="p text-gray-700 text-lg mb-6 text-justify text-sm">

                            </p>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Horaires` : `Schedules`}</h3>
                            <div className='mb-4'>
                                <p className="p text-gray-700 mt-2 text-sm whitespace-pre-line">
                                    {isFr ? `Créneaux :` : `Sessions`}<br />
                                    -{isFr ? `Lundi 20:00 à 22:00 Gymnase T5 ` : `Monday 8:00 to 10:00 PM gymnasium T5`} <br />
                                    -{isFr ? `Mercredi 18:00 à 20:00 Gymnase T5` : `Wednseday 6:00 to 8:00 PM gymnasium T5`}  <br />
                                    -{isFr ? `Vendredi 18:00 à 19:00 Grand Gymnase de l'X` : `Friday 6:00 to 7:00 PM X's great gymnasium`}  <br />
                                </p>
                            </div>

                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Se rendre au grand gymnase :` : `Going to the gymnasium`}</h3>
                            <p className="text-gray-700 text-lg mb-6 text-justify text-sm">{isFr ? `Le grand gymnase de l'X se situe à gauche après la halle des sports quand on arrive depuis Télécom.` : `The great gymnasium of polytechnique is located after the halle des sports when arriving from Télécom.`} </p>
                            <a href={'https://goo.gl/maps/BfVHZDUAxWZJ2a3DA'} className="ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">{isFr ? `lien maps` : `Maps Link`}</a>

                        </div>
                        <div className="md:w-1/2 ml-8 mt-10">
                            <img src={process.env.PUBLIC_URL + `/images/volley.png`} alt={'salle arme'} className="w-full md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>



                </div>
            </div>
        </html>
    )

}
