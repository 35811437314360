import { Calendar } from "../components/Calendar";
import "./Calendrier.css"
interface ScreenProps {
    isFr: boolean
    switchFrEn: () => void
}


// export const Calendrier: React.FC<ScreenProps> = ({ isFr, switchFrEn }) => {

//     // pour que l'iframe fonctionne il faudrait ajouter un header htp de CSP :
//     // Content-Security-Policy: frame-src https://calendar.google.com;
//     // voir avec Clément

//     return (
//         <main>
//             <div className="bg-gray-100 opacity-100 p-8 mt-[2.5rem] h-[75vh] ms:h-[65vh]">
//                 <div className="m-auto h-full">
//                     <h1 className="text-3xl font-bold mb-8">{isFr ? `Calendrier des sports du BDS` : `Calender of the BDS sports`}</h1>
//                     <p>{isFr ? `Voici les différents crénaux des sports proposés.` : `Here are the different slots for the sports.`}</p>
//                     <div className="mt-8 mx-auto sm:w-5/6 sm:h-2/3 h-1/2">
//                         {isFr ? <a
//                             style={{ color: 'blue', textDecoration: 'underline' }}
//                             href="/includes/crenaux.html">Cliquer ici pour accéder aux créneaux</a> :
//                             <a
//                                 style={{ color: 'blue', textDecoration: 'underline' }}
//                                 href="/includes/crenaux.html">Click here to access the time slots</a>}
//                         <br />
//                         {isFr ? <a
//                             style={{ color: 'blue', textDecoration: 'underline' }}
//                             href="/includes/crenaux.pdf">Cliquer ici pour accéder aux créneaux (version PDF)</a> :
//                             <a
//                                 style={{ color: 'blue', textDecoration: 'underline' }}
//                                 href="/includes/crenaux.pdf">Click here to access the time slots (PDF version)</a>}
//                         {/* <Calendar /> */}
//                         {/*<img src={process.env.PUBLIC_URL + `/images/calendrier.png`} alt={'plan de la salle d escalade'} className="w-full md:w-auto mb-4 md:mb-0"/>*/}
//                         {/*<iframe
//                             src={process.env.PUBLIC_URL + "/includes/crenaux.html"}
//                             className="w-full h-full"
//                             id="calendar"
//                             onLoad={() => {
//                                 const iframe: any = document.querySelector('iframe#calendar');
//                                 const contentDoc = iframe.contentDocument;
//                                 const targetElement = contentDoc.getElementsByTagName('table')[0];
//                                 //targetElement.style.transform = 'scale(0.6)';
//                                 //targetElement.style.transformOrigin = '0 0';
//                             }}
//                         /> ne marche pas à cause de la config*/}
//                     </div>
//                 </div>
//             </div>
//         </main>
//     )

// }

export const Calendrier: React.FC<ScreenProps> = ({ isFr, switchFrEn }) => {
    return(
    <main>
        <div className="calendrier">
            <h1 className="text-3xl font-bold mb-8">{isFr ? `Calendrier des sports du BDS` : `Calender of the BDS sports`}</h1>
            <p>Pour voir les horaires des différents sports, rendez vous sur le compte Instagram du BDS : <b><a className='lien_insta' href="http://instagram.com/bdstelecomparis">@bdstelecomparis</a></b></p>
        </div>
    </main>
    )
}