
interface ScreenProps{
    isFr: boolean;
    SwitchFrEn: ()=>void;
  }

  export const TennisDeTable: React.FC<ScreenProps> = ({isFr, SwitchFrEn}) =>{
    

    
    return (
    <html>
    <div className="bg-gray-100 opacity-100 p-8 mb-[100px] mt-[50px]">
    <div className="mb-18">
        <h2 className="text-4xl font-bold mb-8">Tennis de table</h2>
    </div>
    
    
    <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">
 
                 <div className='flex flex-col md:flex-row'>
                 <div className="md:w-1/2 mb-8">
                     <h3 className="text-xl font-bold mb-8 text-justify">{isFr ? `Respo Sport au Quotidien : Clara chelala`:`Sport Manager : Clara chelala`} </h3>
                     <h3 className="text-xl font-bold mb-4 text-justify">  {isFr ? `Infos pratiques`:`General Infos`}</h3>
                         <p className="p text-gray-700 text-lg mb-6 text-justify text-sm">
                         {isFr ? `Entraînements organisés en autonomie avec le responsable tennis de table.`:`Trainnings are organised by the Sport Manager`}
                         
                         </p>
    
                     
                     </div>
                     <div className="md:w-1/2 ml-8 mt-10">
                         <img src={process.env.PUBLIC_URL + `/images/pingpong.jpg`} alt={'pongiste'} className="w-full md:w-auto mb-4 md:mb-0"/>
                     </div>
                 </div>
 
                 
                
    </div>
    </div>
    </html>
    )
 
 }
     