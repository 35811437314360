

import React, { useState, useEffect } from 'react';

interface ScreenProps {
    isFr: boolean;
    SwitchFrEn: () => void;
}

export const Athletisme: React.FC<ScreenProps> = ({ isFr, SwitchFrEn }) => {

    return (
        <html>
            <div className="bg-gray-100 opacity-100 p-8 mb-[100px] mt-[50px]">
                <div className="mb-18">
                    <h2 className="text-4xl font-bold mb-8">Athletisme</h2>
                </div>

                <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">
                    <div className='flex flex-col md:flex-row'>
                        <div className="md:w-1/2 mb-8">
                            <h3 className="text-xl font-bold mb-8 text-justify">{isFr ? `Respo Sport au Quotidien : Iba Demesy ` : `Sport Manager : Iba Demesy`} </h3>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Infos pratiques` : `General Infos`}</h3>
                            <p className="p text-gray-700 text-lg mb-6 text-justify text-sm">
                                {isFr ? `Accès libre à la piste d'athlétisme de Polytechnique sur un créneau de 2h.` : `Free acess to the running track`}


                            </p>
                            <h3 className="text-xl font-bold mb-4 text-justify">Horaires</h3>
                            <div className='mb-4'>
                                <p className="p text-gray-700 mt-2 text-sm whitespace-pre-line">
                                    {isFr ? `Créneaux :` : `Sessions`}<br />

                                    - {isFr ? `Mardi de 18:00 à 20:00 Piste de l'ENSTA` : `Tuesday from 6:00 PM to 8:00 PM ENSTA running track`}
                                    <br />
                                    {isFr ? `ps : la piste est souvent libre notamment les weekends` : `Ps: the track is often free especially during weekends`}

                                </p>
                            </div>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Se rendre à la piste :` : `Going to the track:`}</h3>
                            <p className="text-gray-700 text-lg mb-6 text-justify text-sm"> {isFr ? `La piste se situe après la salle de musculation en direction de l'ENSTA` : `The track is after the workout room heading toward ENSTA`}</p>
                            <a href={'https://goo.gl/maps/uQ1pb2ajYaFkKDNJ9'} className="ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">{isFr ? `Lien Maps` : `Maps Link`}</a>


                        </div>
                        <div className="md:w-1/2 ml-8 mt-10">
                            <img src={process.env.PUBLIC_URL + `/images/athletisme.png`} alt={'personnes qui courent'} className="w-full md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row'>
                        {/* <div className="md:w-1/2 mb-8">
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Calendrier` : `Calendar`}</h3>
                            <p className="mb-4">{isFr ? `Il peut mettre du temps à charger` : ` It can take some time to load`}</p>
                            <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FParis&showTitle=0&showDate=1&showPrint=0&showCalendars=0&showTabs=0&showTz=0&mode=WEEK&src=Y19mNjBkNmE4MjBjMmI4ODlkMTg2MzY3MWFmMjMwMGE3YTgxMDVmZDM4OTVhNzA5NTI0YmI1MDkyNjFmNDk0Yzg1QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23009688" width="600" height="450" className='mb-4 w-[100%] w-[100%]' ></iframe>
                            <a href={'https://calendar.google.com/calendar/u/5?cid=Y19mNjBkNmE4MjBjMmI4ODlkMTg2MzY3MWFmMjMwMGE3YTgxMDVmZDM4OTVhNzA5NTI0YmI1MDkyNjFmNDk0Yzg1QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20'} className="ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">Enregistrer le calendrier</a>

                        </div>*/}

                        <div className="md:w-1/2 md:ml-20 mt-20 ms:ml-4">
                            <p className="text-xl font-bold mb-4 text-justify">{isFr ? `Plan` : `Map`}</p>
                            <img src={process.env.PUBLIC_URL + `/images/stade ensta.png`} alt={'personnes qui courent'} className="w-full md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>

                </div>
            </div>
        </html>
    )

}
