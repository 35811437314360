import './Card.css';
import React, { useState } from 'react';


type ProfileProps = {
    profilePic: string
    name: string
    sport: string
    text: string
}

export const Card: React.FC<ProfileProps> = ({ profilePic, name, sport, text }) => {
    const [flipped, setFlipped] = useState(true);
    const [hovered, setHovered] = useState(false);
    const handleClik = () => {
        setFlipped(!flipped);
    };

    return (
        <div className={` ${flipped ? 'flipped' : ''}`}
            onClick={handleClik}
            style={{ zIndex: hovered ? 2 : 1 }}
            onMouseEnter={(e) => {
                setHovered(true);
            }}
            onMouseLeave={(e) => {
                setHovered(false);
            }}
        >
            {flipped ? (
                <div className='card zoom-in-hover'>
                    <div className='front'>
                        <div className="flex flex-col items-center justify-center">
                            <h2 className="font-bold text-lg mb-2 text-white">{sport}</h2>
                            <img src={process.env.PUBLIC_URL + `/images/${profilePic}`} alt={sport} className="w-40 h-40 mr-1 mb-2" />
                            <p className="text-gray-700 mt-2 text-sm">{"Respo" + name}</p>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='card zoom-on-hover'>
                    <div className="back">
                        <div className="flex flex-col items-center justify-center ">
                            <h2 className="font-bold text-lg mb-2 text-white">{sport}</h2>
                            <p className="p text-gray-700 mt-2 text-sm">
                                {text}
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Card;
