




import React, { useState, useEffect } from 'react';

interface ScreenProps {
    isFr: boolean;
    SwitchFrEn: () => void;
}

export const Natation: React.FC<ScreenProps> = ({ isFr, SwitchFrEn }) => {



    return (
        <html>
            <div className="bg-gray-100 p-8">
                <div className="mb-18">
                    <h2 className="text-4xl font-bold mb-8">  {isFr ? `Natation` : `Swimming`}</h2>
                </div>


                <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">

                    <div className='flex flex-col md:flex-row'>
                        <div className="md:w-1/2 mb-8">
                            <h3 className="text-xl font-bold mb-8 text-justify">{isFr ? `Respo Sport au Quotidien :` : `Sport Manager :`} Paul Archer(X), Lucas Michel & Mikael Mazars (piscine municipale)</h3>
                            <h3 className="text-xl font-bold mb-4 text-justify">  {isFr ? `Infos pratiques` : `Main Infos`}</h3>
                            <p className="p text-gray-700 text-lg mb-6 text-justify text-sm">
                                {isFr ? `Les séances se déroulent à la piscine Camille Claudel.` : `The sessions are at the Camille Claudel pool`}
                                <a href={'https://www.lavague-cps.com/'} className='text-lg mb-6 text-blue-700 text-sm mb-4'>  {isFr ? `Le site de la piscine` : `The pool Website`}</a>
                                {isFr ? ` Il y en a deux fixes par semaines : le jeudi et le dimanche après midi mais si tu veux nager plus, il est possible d’en faire d’avantage en demandant au préalable.` : ` There are two fixed sessions per week: Thursday and Sunday afternoons, but if you want to swim more, it is possible to do so by requesting it in advance.`}<br />
                                {isFr ? `Il est également  possible pour toi d’aller nager à la piscine de l’X en adhérant au CSX. Le coût est de 78€ pour l’année (en plus de la cotisation BDS) mais la moitié de la somme est remboursée par le BDS. Tu auras accès aux deux bassins de l’X du lundi au vendredi de 12h30 à 13h30 et de 17h30 à 18h30 (sauf le mercredi pour l’après midi)` : `It is also possible for you to go swimming at the pool of l'X by joining the CSX. The cost is 78€ per year (in addition to the BDS membership fee), but half of the amount is refunded by the BDS. You will have access to both pools at l'X from Monday to Friday from 12:30 pm to 1:30 pm and from 5:30 pm to 6:30 pm (except Wednesday afternoons).`}


                            </p>
                            <h3 className="text-xl font-bold mb-4 text-justify">  {isFr ? `Horaires` : `Schedules`}</h3>
                            <div className='mb-4'>
                                <p className="p text-gray-700 mt-2 text-sm whitespace-pre-line">
                                    {isFr ? `Créneaux :` : `Sessions`}<br />
                                    -  {isFr ? `Jeudi de 16:30 à 19:00` : `Thursday from 4:30 to 7:00 PM`}  Piscine Camille Claudel <br />
                                    -   {isFr ? `Dimanche de 16:00-16:30 à 19:00` : `Sunday from 4:00 to 7:00 PM`} Picsine Camille Claudel

                                </p>
                            </div>
                            <h3 className="text-xl font-bold mb-4 text-justify">  {isFr ? `Se rendre a la piscine :` : `Going to the pool`} </h3>
                            <p className="text-gray-700 text-lg mb-6 text-justify text-sm">   {isFr ? `Pour y accéder, rien de plus simple, le bus 91.10 ou 91.06 t’y emmènent en 10 minutes (arrêt Camille Claudel) et si tu veux vraiment appuyer, tu peux y aller en courant en 25min ! ` : `In order to get there, there is the bus 91.06 stop Camille Claudel that takes you there in 10 minutes otherwise you can run !!`}</p>
                            <a href={'https://goo.gl/maps/1qm9GcotNGiyrr4a8'} className=" ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">  {isFr ? `Lien Maps` : `Maps Link`}</a>


                        </div>
                        <div className="md:w-1/2 ml-8 mt-10">
                            <img src={process.env.PUBLIC_URL + `/images/salle piscine.png`} alt={'piscine'} className="md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row'>
                        {/*
                <div className="md:w-1/2 mb-8">
                <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Calendrier`:`Calendar`}</h3>
                        <p className="mb-4">{isFr ? `Il peut mettre du temps à charger`:` It can take some time to load`}</p>
                        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FParis&showDate=0&showPrint=0&showTabs=0&showCalendars=0&showTz=0&mode=WEEK&src=Y19jZmI3MzRjZjYxZTM0YWE0NzA4ZGU3YWZiYzdkZDliZmEwZWVmZGNmYjEyMGM3Yzg4MjIwYTQ1ZWIzYzUyZDc5QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23E67C73" width="600" height="450" className='mb-4 w-[100%]' ></iframe>
                        
                        <a href={'https://calendar.google.com/calendar/u/5?cid=Y19jZmI3MzRjZjYxZTM0YWE0NzA4ZGU3YWZiYzdkZDliZmEwZWVmZGNmYjEyMGM3Yzg4MjIwYTQ1ZWIzYzUyZDc5QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20'} className=" ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">  {isFr ? `Enregistrer le calendrier`:`Save the Calendar`}</a>
                
   </div> */}


                    </div>


                </div>
            </div>
        </html>
    )

}
