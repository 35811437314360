import './App.css';
import { Home } from './screens/Home';
import { Sports } from './screens/Sports'
import { Routes, Route, NavLink } from 'react-router-dom';
import { Contact } from './screens/Contact';
import { SocialLinks } from './components/SocialLinks';
import { Menu } from './components/Menu';
import { Organigramme } from './screens/Organigramme';
import { Calendrier } from './screens/Calendrier';
import { Cotiser } from './screens/Cotiser';
import { Basket } from './screens/sports/Basket';
import { Football } from './screens/sports/Football';
import { FootballFeminin } from './screens/sports/FootballFeminin';
import { Handball } from './screens/sports/Handball';
import { TennisDeTable } from './screens/sports/Tennis-de-table';
import { Escalade } from './screens/sports/Escalade';
import { Natation } from './screens/sports/Natation';
import { Athletisme } from './screens/sports/Athletisme';
import { Badminton } from './screens/sports/Badminton';
import { Musculation } from './screens/sports/Musculation';
import { Boxe } from './screens/sports/Boxe';
import { MMA } from './screens/sports/Mma';
import { Escrime } from './screens/sports/Escrime';
import { Test } from './screens/Test';
import Switch from './components/Switch';
import { Volley } from './screens/sports/Volley';
import { EkidenPage } from './screens/Ekiden';
import { useState } from 'react';

function App() {
  const [isFr, setIsFr] = useState(true);

  const switchFrEn = () => {
    setIsFr(!isFr);
  }
    return (
        <div
            style={{
                backgroundImage: `url("${process.env.PUBLIC_URL}/images/back.png")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center top',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    minHeight: '100vh',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Menu isFr={isFr} SwitchFrEn={switchFrEn} />
                <main className="container mx-auto ms:px-2rem px-[0.7rem]">
                    <div>
                        <Routes>
                            <Route path='/' element={<Home isFr={isFr} SwitchFrEn={switchFrEn} />} />
                            <Route path='/sports' element={<Sports isFr={isFr} switchFrEn={switchFrEn} />} />
                            <Route path='/organigramme' element={<Organigramme />} />
                            <Route path='/contact' element={<Contact />} />
                            <Route path='/calendrier' element={<Calendrier isFr={isFr} switchFrEn={switchFrEn} />} />
                            <Route path='/cotisation' element={<Cotiser isFr={isFr} switchFrEn={switchFrEn} />} />
                            <Route path='/basket' element={<Basket isFr={isFr} SwitchFrEn={switchFrEn} />} />
                            <Route path='/football' element={<Football isFr={isFr} SwitchFrEn={switchFrEn} />} />
                            <Route path='/football-feminin' element={<FootballFeminin isFr={isFr} SwitchFrEn={switchFrEn} />} />
                            <Route path='/handball' element={<Handball isFr={isFr} SwitchFrEn={switchFrEn} />} />
                            <Route path='/tennis-de-table' element={<TennisDeTable isFr={isFr} SwitchFrEn={switchFrEn} />} />
                            <Route path='/escalade' element={<Escalade isFr={isFr} SwitchFrEn={switchFrEn}/>} />
                            <Route path='/natation' element={<Natation isFr={isFr} SwitchFrEn={switchFrEn}/>} />
                            <Route path='/athletisme' element={<Athletisme isFr={isFr} SwitchFrEn={switchFrEn} />} />
                            <Route path='/badminton' element={<Badminton isFr={isFr} SwitchFrEn={switchFrEn}/>} />
                            <Route path='/musculation' element={<Musculation isFr={isFr} SwitchFrEn={switchFrEn}/>} />
                            <Route path='/boxe' element={<Boxe isFr={isFr} SwitchFrEn={switchFrEn}/>} />
                            <Route path='/MMA' element={<MMA isFr={isFr} SwitchFrEn={switchFrEn}/>} />
                            <Route path='/Escrime' element={<Escrime isFr={isFr} SwitchFrEn={switchFrEn}/>} />
                            <Route path='/Volley' element={<Volley isFr={isFr} SwitchFrEn={switchFrEn}/>} />
                            <Route path='/test' element={<Test />} />
                            <Route path='/Ekiden' element={<EkidenPage isFr={isFr} switchFrEn={switchFrEn}/>} />

                        </Routes>
                    </div>
                </main>

                <footer className='bottom-0 left-0 right-0 z-50 '>
                    <SocialLinks isFr={isFr} switchFrEn={switchFrEn} />
                </footer>
            </div>
        </div>
    );
}

export default App;
