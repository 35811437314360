

import React, { useState, useEffect } from 'react';

interface ScreenProps {
    isFr: boolean;
    SwitchFrEn: () => void;
}

export const Escalade: React.FC<ScreenProps> = ({ isFr, SwitchFrEn }) => {



    return (
        <html>
            <div className="bg-gray-100 opacity-100 p-8 mb-[100px] mt-[50px]">
                <div className="mb-18">{isFr ? `` : ``}
                    <h2 className="text-4xl font-bold mb-8">{isFr ? `Escalade` : `Climbing`}</h2>
                </div>


                <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">

                    <div className='flex flex-col md:flex-row'>
                        <div className="md:w-1/2 mb-8">
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Respos Sport au Quotidien :` : `Sport Managers`} <br />
                            </h3> <h3 className="text-xl font-bold mb-8 text-justify">Victor Deru (Arkose), Martin de Bats (pan de l'X)  </h3>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Infos Pratique` : `General Infos`}</h3>
                            <p className="p text-gray-700 text-lg mb-6 text-justify text-sm">
                                {isFr ? `Entraînements en autonomie sur le Pan de l'X. Des entraînements à l'Arkose de Massy ont aussi lieux, remboursés à 50% le mardi, le jeudi et le dimanche. La première séance est gratuite. Petit conseil y aller tôt en weekend elle peut être pas mal fréquentée. Afin d'accéder a la salle de l'X, vous avez besoin du badge, demander au respo. Il faut avoir ses propres chaussons d'escalade pour le pan de l'X, le BDS n'en a pas.` : `Self-training sessions on the Pan de l'X. Training sessions also take place at Arkose in Massy, reimbursed at 50% on Tuesdays, Thursdays, and Sundays. The first session is free. A small tip is to go early on weekends as it can get quite crowded. To access the l'X gym, you need a badge, which you can request from the responsible person. You need to have your own climbing shoes for the Pan de l'X, as the BDS does not provide them.`}



                            </p>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Horaires` : `Schedules`}</h3>
                            <div className='mb-4'>
                                <p className="p text-gray-700 mt-2 text-sm whitespace-pre-line">
                                    - {isFr ? `Lundi de 17:30 à 20:00 Pan de l'X` : `Thursday from 5:30 PM to 8:00 PM Pan de l'X`} <br />
                                    {isFr ? `Créneaux :` : `Sessions`}<br />
                                    - {isFr ? `Mardi de 21:30 à 00:00 Arkose Massy` : `Tuesday from 9:30 PM to 12:00 PM Arkose Massy`} <br />
                                    - {isFr ? `Jeudi de 14:00 à 16:00 Pan de l'X` : `Thursday from 2:00 PM to 4:00 PM Pan de l'X`} <br />
                                    - {isFr ? `Jeudi de 21:30 à 00:00 Arkose Massy` : `Thursday from 9:30 PM to 12:00 PM Arkose Massy`} <br />
                                    - {isFr ? `Dimanche de 18:30 à 21:00 Arkose Massy` : `Sunday from 6:30 PM to 9:00 PM Arkose Massy`} <br />
                                </p>
                            </div>
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Se rendre à la salle de l'X :` : `Going to the wall`}</h3>
                            <p className="text-gray-700 text-lg mb-6 text-justify text-sm"> {isFr ? `La salle se situe en face de la salle de musculation, il s'agit d'une petite porte sur le côté du gymnase.` : `The room is facing the workout room, it is a small door on the side of the T6`}</p>
                            <a href={'https://goo.gl/maps/8R1fN8df89PfzaKU9'} className=" ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md mb-8">{isFr ? `Lien Maps` : `Maps Link`}</a>
                            <br />
                            <br />
                            <br />
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Se rendre à Arkose :` : `Going to Arkose`}</h3>
                            <p className="text-gray-700 text-lg mb-6 text-justify text-sm">{isFr ? `Arkose est une salle de bloc privé qui se situe à Massy.` : `Arkose is a private climbing wall located in Massy`}  <a href={'https://arkose.com/massy'} className='text-lg mb-6 text-blue-700 text-sm mb-4'>{isFr ? `Le site de la salle` : `the wall website`}</a></p>

                            <a href={'https://goo.gl/maps/4QAEpnda3fGHtzJ38'} className=" ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">{isFr ? `Lien Maps` : `Maps Link`}</a>
                        </div>
                        <div className="md:w-1/2 ml-8 mt-40">
                            <img src={process.env.PUBLIC_URL + `/images/Arkose.jpg`} alt={'salle Arkose'} className="w-full md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row'>
                        {/*
                        <div className="md:w-1/2 mb-8">
                            <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Calendrier` : `Calendar`}</h3>
                            <p className="mb-4">{isFr ? `Il peut mettre du temps à charger` : ` It can take some time to load`}</p>
                            <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FParis&showTitle=0&showPrint=0&showTabs=0&showCalendars=0&showTz=0&mode=WEEK&src=Y180NTE2ZTEyODNkNjc2Njg4YWQ5NjEwZGIxYTg1NmJhNDJmNTg4ODU4OTEyM2VmMzUyNTY5ZDYzZGRiZWU4YzAzQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%237CB342" width="600" height="450" className='mb-4 w-[100%]' ></iframe>

                            <a href={'https://calendar.google.com/calendar/u/5?cid=Y180NTE2ZTEyODNkNjc2Njg4YWQ5NjEwZGIxYTg1NmJhNDJmNTg4ODU4OTEyM2VmMzUyNTY5ZDYzZGRiZWU4YzAzQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20'} className=" ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">{isFr ? `Enregistrer le calendrier` : `Save the calendar`}</a>

                        </div>
    */}
                        <div className="md:w-1/2 md:ml-20 mt-20">
                            <p className="text-xl font-bold mb-4 text-justify">{isFr ? `Plan` : `Map`}</p>
                            <img src={process.env.PUBLIC_URL + `/images/salle escalade.png`} alt={'plan de la salle d escalade'} className="w-full md:w-auto mb-4 md:mb-0" />
                        </div>
                    </div>

                </div>
            </div>
        </html>
    )

}
