import Card from "../components/Card"

export const Organigramme = () => {
    return (
        <main>
            <div className="bg-gray-100 opacity-80 py-8 px-24 mt-[2.5rem] mb-[150px]">
                <div className="mb-18">
                    <h2 className="text-4xl font-bold mb-8">Organigramme</h2>
                </div>
                <h2 className="text-2xl font-bold mb-4">Responsables des sports au quotidien</h2><br />
                <div className="grid md:grid-cols-5 gap-20 mb-8">
                    <Card name="Martin" profilePic="ppsportif.png" sport="Basketball" text="Basket avec coach le lundi soir, autonomie le Vendredi. Le gymnase T6 se situe sur la droite de la halle des sports lorsqu'on arrive de Télécom.

    Créneaux :
    - Lundi de 20:00 à 22:00 Gymnase T6 de l'X
    - Vendredi de 17:30 à 19:30 Gymnase T6 de l'X"/>
                    <Card name="Martin" profilePic="ppsportif.png" sport="Tennis de table" text="Entraînements organisés en autonomie avec le responsable tennis de table." />
                    <Card name="Martin" profilePic="ppsportif.png" sport="Escalade"
                        text="Entraînements en autonomie sur le Pan de l'X. Des entraînements à l'Arkose de Massy ont aussi lieux, remboursés à 50% le mardi, le jeudi et le dimanche.
    Créneaux :
    - Mardi de 19:00 à 21:00 Pan de l'X
    - Jeudi de 19:00 à 21:00 Pan de l'X
    - Mardi de 21:30 à 23:59 Arkose Massy
    - Jeudi de 21:30 à 23:59 Arkose Massy
    - Dimanche de 18:30 à 21:00 Arkose Massy"/>
                    <Card name="Martin" profilePic="ppsportif.png" sport="Handball " text="Entraînement avec coach le lundi, en autonomie le mercredi. Le grand gymnase de l'X se situe à gauche après la halle des sports quand on arrive depuis Télécom.

    Créneaux :
    - Lundi de 17:30 à 19:30 Grand gymnase de l'X
    - Mercredi de 20:00 à 22:00 Grand gymnase de l'X"/>
                    <Card name="Martin" profilePic="ppsportif.png" sport="Natation " text="Les créneaux débutent entre 16h30 et 17h selon les disponibilité des gens, la piscine ferme entre 18h30 et 19h. Vous pouvez nager seul.e ou demander un entraînement. La piscine est accessible en bus 91.06/91.10

    Créneaux :
    - Jeudi de 16:30 à 19:00 Piscine Camille Claudel
    - Dimanche de 16:30 à 19:00 Picsine Camille Claudel"/>
                    <Card name="Martin" profilePic="ppsportif.png" sport="Arts martiaux" text="" />
                    <Card name="Martin" profilePic="ppsportif.jpg" sport="Football" text="Entraînements encadrés par un coach le lundi et le jeudi. Le terrain synthétique se situe à gauche en arrivant au niveau de l'ENSTA quand on arrive depuis Télécom.

    Créneaux :
    - Lundi de 19:30 à 21:30 Terrain synthétique de l'X
    - Jeudi de 20:00 à 22:00 Terrain synthétique de l'X"/>

                    <Card name="Martin" profilePic="ppsportif.jpg" sport="Danse Paris-Saclay" text="Créneaux:
        -Lundi de 18h30 à 20h Salle d'expression corporelle"/>

                    <Card name="Martin" profilePic="ppsportif.jpg" sport="Athlétisme" text="Accès libre à la piste d'athlétisme de Polytechnique sur un créneau de 2h. La piste se situe après l'ENSTA quand on arrive depuis Télécom.

    Créneaux :
    - Mardi de 18:00 à 20:00 Piste d'athlétisme de l'X"/>

                    <Card name="Martin" profilePic="ppsportif.jpg" sport="Musculation" text="Accès à la salle de la halle des sports de polytechnique en autonomie sur les créneaux réservés.

    Créneaux :
    - Mardi de 19:00 à 21:00 Halle des Sports de l'X
    - Vendredi de 17:30 à 19:30 Halle des Sports de l'X"/>

                    <Card name="Martin" profilePic="ppsportif.jpg" sport="Tennis de table" text="Entraînements organisés en autonomie avec le responsable tennis de table." />
                    <Card name="Martin" profilePic="ppsportif.jpg" sport="Natation" text="Les créneaux débutent entre 16h30 et 17h selon les disponibilité des gens, la piscine ferme entre 18h30 et 19h. Vous pouvez nager seul.e ou demander un entraînement. La piscine est accessible en bus 91.06/91.10

    Créneaux :
    - Jeudi de 16:30 à 19:00 Piscine Camille Claudel
    - Dimanche de 16:30 à 19:00 Picsine Camille Claudel"/>
                    <Card name="Martin" profilePic="ppsportif.jpg" sport="Badminton" text="Entraînement en autonomie. Le gymnase T5 se situe à droite de la halle des sports de Polytechnique quand on arrive depuis Télécom.

    Créneaux :
    - Mercredi de 20:00 à 22:00 Gymnase T5 de l'X"/>
                    <Card name="Martin" profilePic="ppsportif.jpg" sport="Boxe" text="Entraînement de Boxe anglaise encadrée par une coach. La salle de boxe se situe au bout de la halle des sports de Polytechnique, au rez-de-chaussée

    Créneaux :
    - Mercredi de 19:00 à 21:00 Halle des Sports de l'X"/>
                    <Card name="Martin" profilePic="ppsportif.jpg" sport="Rock" text="Mardi 20h-21h" />
                </div>
            </div>
        </main>
    )
}