import { About } from "../components/About";
import { YoutubeVideo } from "../components/YoutubeVideo";
import { SocialLinks } from "../components/SocialLinks";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

interface ScreenProps {
    isFr: boolean;
    SwitchFrEn: () => void;
}

export const Home: React.FC<ScreenProps> = ({ isFr, SwitchFrEn }) => {

    const imagePaths = [
        "/images/home/4351_nH6B5REbaq2PW1mv00cS.jpg",
        "/images/home/6375_dCTuCtFfpE0OWM7xysWv.jpg",
        "/images/home/5220_gAe4Z1RC6bVNrIdzd5tH.jpg",
        "/images/home/6409_iOCpNn2X1s8A2JDJOxJn.jpg",
        "/images/home/5924_RHR7u0xVqTLEUaNX1Puc.jpg",
        "/images/home/6431_1HGWOk1EYUg2Jy39jinH.jpg",
        "/images/home/5979_8W36TKeYaiV8N0xbaWRu.jpg",
        "/images/home/6836_u9tOtAZQiwhYCEvI7aEU.jpg",
        "/images/home/5985_cFBamwuhZdMLxREoRieH.jpg",
        "/images/home/7129_q1MmhqRYDB5XmjT9FTLh.jpg",
        "/images/home/6040_n5Yr5rfx7yT1uVTxahHw.jpg",
        "/images/home/9279_wkIbjrhMMeIc5cE34pcy.jpg",
        "/images/home/6077_OyfWzvudqSuOGk2lyFK0.jpg",
        "/images/home/9319_KcTHD2OEdwpTYGofGYhs.jpg",
        "/images/home/6299_adm8XNVCe4A3ROl2ipf0.jpg",
    ];


    return (

        <main className="flex justify-center mt-[2.5rem]">
            <div className="m-auto">
                <div className="bg-gray-100 opacity-100 p-3 pt-6 ms:p-8">

                    <h2 className="text-3xl font-bold mb-4 ml-5">{isFr ? `Le BDS de Telecom Paris` : `Telecom Paris' Sports Bureau`}</h2>

                    <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">
                        <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Présentation Générale` : `General Presentation`}</h3>
                        <p className="text-gray-700 text-lg mb-6 text-justify">
                            {isFr ? `Le BDS de Telecom Paris est une association sportive qui propose une grande variété d'activités pour tous les étudiants de l'école. Que vous soyez un athlète confirmé ou débutant, vous trouverez certainement une activité qui vous convient. Le BDS offre une gamme de sports d'équipe tels que le football, le basketball, le volley-ball et le handball, ainsi que des sports individuels comme la course à pied, la natation et la musculation. En plus de cela, le BDS organise régulièrement des événements sportifs, des compétitions et des tournois pour les membres. Rejoindre le BDS est une excellente façon de rester en forme, de rencontrer de nouvelles personnes et de profiter de toutes les activités sportives que l'école a à offrir.` : `The Telecom Paris BDS is a sports association that offers a wide variety of activities for all students of the school. Whether you are an experienced athlete or a beginner, you will certainly find an activity that suits you. The BDS offers a range of team sports such as football, basketball, volleyball and handball, as well as individual sports such as running, swimming and weightlifting. In addition to this, the BDS regularly organizes sports events, competitions and tournaments for its members. Joining the BDS is an excellent way to stay in shape, meet new people and enjoy all the sports activities that the school has to offer.`}

                        </p>
                    </div>

                    <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">
                        <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Attention : site actuellement en refonte` : `Attention : website is currently being modified`}</h3>
                        <p className="text-gray-700 text-lg mb-6 text-justify">
                            {isFr ? `Le site n'est pas complet. La refonte du site arrivera avant le début de l'année scolaire.` : `The website is not complete. The new website will arrive before the schoolyear.`}

                        </p>
                    </div>

                    <div className="bg-white p-6 shadow-md rounded-md mb-8">

                        <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Devenir adhérent` : `Become a member`}</h3>
                        <p className="text-gray-700 text-lg mb-4">
                            {isFr ? `Pour pratiquer toutes les activités sportives disponibles et bénéficier de tarifs préférentiels pour la plupart des événements organisés par le BDS, vous pouvez devenir adhérent à l'association.` : `To practice all available sports activities and benefit from preferential rates for most events organized by the BDS, you can become a member of the association.`}

                        </p>
                        <a href="/cotisation" className="inline-block bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md mt-4">{isFr ? `Devenir adhérent` : `Become a member`}</a>

                    </div>

                    <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">
                        <div
                            className="w-[90vw] md:w-3/4 mx-auto"
                        >
                            <Carousel
                                dynamicHeight={false}
                                autoPlay={false}
                                infiniteLoop={true}
                                statusFormatter={(current: any, total: any) => `${current} sur ${total}`}
                            >
                                {imagePaths.map((imagePath, index) => (
                                    <div key={index}>
                                        <img
                                            src={process.env.PUBLIC_URL + imagePath}
                                            alt={`Image ${index + 1}`}
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>


                    <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">
                        <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Le mot du président` : `The word of the president`}</h3>

                        <div className='flex flex-col md:flex-row'>
                            <div className="md:w-2/3 mb-8">

                                <p className="text-gray-700 text-lg mb-6 text-justify">
                                    {isFr ? `Bienvenue sur le site du Bureau des Sports de Telecom Paris ! Nous vous offrons une vaste gamme de sports afin que chacun puisse trouver sa place et s'épanouir pleinement tout au long de sa vie étudiante.

Nous organisons également des événements sportifs majeurs tels que l'Ekiden (un marathon en relais autour du plateau), ainsi que des compétitions inter-écoles comme la Coupe de l'X, le CCL, le Cartel des Mines et le TOSS.

En tant que président du BDS, ma mission principale est de fédérer le plus grand nombre d'étudiants autour du sport et de promouvoir des valeurs communes de solidarité, de respect et de dépassement de soi.

Que vous soyez débutant ou athlète confirmé, notre objectif est de vous offrir un accès facile et de qualité à la pratique sportive.

Je suis très enthousiaste à l'idée de vous représenter cette année et de vous offrir la meilleure expérience sportive possible !

Sportivement,

Mathieu BOUTEILLER
Président du Bureau des Sports, Telecom Paris` : `Welcome to the Telecom Paris Sports Office website! We offer a wide range of sports so that everyone can find their place and fully flourish throughout their student life.

We also organize major sporting events such as the Ekiden (a relay marathon around the plateau), as well as inter-school competitions such as the Coupe de l'X, the CCL, the Cartel des Mines and the TOSS.

As president of the BDS, my main mission is to unite as many students as possible around sport and to promote common values ​​of solidarity, respect and surpassing oneself.

Whether you are a beginner or an experienced athlete, our goal is to offer you easy, quality access to sports.

I am very excited to represent you this year and offer you the best sporting experience possible!

Sportingly,

Mathieu BOUTEILLER
President of the Sports Office, Telecom Paris`}<br />
                                </p><p className="text-gray-700 text-lg mb-6 text-justify">
                                    {isFr ? `Que cette année soit remplie de succès et de moments sportifs mémorables !` : `May this year be succesfull and full of memorable sport moments  `}

                                    
                                </p>
                            </div>
                            <div className="md:w-1/3 ml-4">
                                <img src={process.env.PUBLIC_URL + `/images/pp_mathieu.jpg`} alt={'Mathieu, Président du BDS'} className="w-auto mb-4" />
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </main>
    )

}