import { useForm, SubmitHandler } from "react-hook-form"
//import nodemailer from 'nodemailer';

type Inputs = {
    mail_object: string
    address_mail: string
    message: string
  }

export const Contact = () =>{
    /*const transporter = nodemailer.createTransport({
        host: 'smtp.gmail.com',
        port: 587,
        secure: false,
        requireTLS: true,
        auth:{
            user: 'mencle69@gmail.com',
            pass: 'bdspassword'
        }
    });
*/
    const {
        register,
        handleSubmit,
        formState: {errors} } = useForm<Inputs>()
    
        const onSubmit: SubmitHandler<Inputs>=data=>{
        console.log(data)
        // Ici, ce fait le traitement lorsque le bouton est cliqué
        /*
        const message={
            from: 'mencle69@gmail.com',
            to: 'clementtrabet@gmail.com',
            subject: data.mail_object,
            html: '<h1>Message envoyé depuis le site du bds</h1><p>'+data.message+'</p><\br><p> L adresse mail de contacte est :'+data.address_mail,
        }

        transporter.sendMail(message, (error, info)=> {
            if(error){
                console.log(error);
            }else{
                console.log('Email sent: ' +info.response);
            }
        }); */
    } 

    return (
            <form className="max-w-lg mx-auto mt-10" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
                <label htmlFor="subject" className="block font-medium text-gray-700 mb-2">Sujet du mail</label>
                <input {...register("mail_object", { required: true })} type="text" className="w-full px-3 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Quel est le sujet" />
                {errors.mail_object && <span className="text-red-500 text-sm">* L'objet du mail est obligatoire</span>}
            </div>
            <div className="mb-6">
                <label htmlFor="email" className="block font-medium text-gray-700 mb-2">Votre adresse mail</label>
                <input {...register("address_mail", { required: true })} type="email" className="w-full px-3 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email" />
                {errors.address_mail && <span className="text-red-500 text-sm">* Le champ adresse mail est obligatoire</span>}
            </div>
            <div className="mb-6">
                <label htmlFor="message" className="block font-medium text-gray-700 mb-2">Votre message</label>
                <textarea rows={10} {...register("message", { required: true })} className="w-full px-3 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Message"></textarea>
                {errors.message && <span className="text-red-500 text-sm">* Le message ne doit pas être vide</span>}
            </div>
            <div className="flex justify-center">
                <button type="submit" className="bg-purple-800 hover:bg-blue-800 text-white font-bold py-3 px-6 rounded-md">Envoyer</button>
            </div>
            </form>

    )
}