import { FaFacebook, FaFacebookF, FaInstagram, FaMailBulk, FaMailchimp, FaYoutube } from 'react-icons/fa'
import React, { useState } from 'react';


type ScreenProps = {
    isFr: boolean
    switchFrEn:()=>void
}

export const SocialLinks: React.FC<ScreenProps> = ({isFr, switchFrEn}) => {

    const [isHovered, setIsHovered] = useState(0)

    return (
        <div>
            <header className="sm:flex justify-between items-center bg-primary py-4 px-6 mt-3">
                <h2 className="text-tertiary sm:text-xl text-m sm:w-1/5 underline sm:no-underline ">{isFr ? `Retrouvez nous :`:`Find us :`}</h2>
                <ul className="flex flex-row flex-wrap sm:flex-nowrap justify-center align-center sm:w-2/5">
                    <li className="flex justify-between items-center w-1/2 sm:mr-8">
                        <a className="hover:underline hover:text-blue-500 text-tertiary flex items-center"
                            href="https://www.instagram.com/bdstelecomparis/"
                            style={{
                                color: isHovered == 1 ? '#2a0606' : 'white'
                            }}
                            onMouseEnter={(e) => {
                                setIsHovered(1);
                            }}
                            onMouseLeave={(e) => {
                                setIsHovered(0);
                            }}
                        >
                            <svg className="w-12 h-12 fill-current mt-4 mr-1" viewBox="0 0 24 24"><FaInstagram /></svg>
                            Instagram
                        </a>
                    </li>
                    <li className="flex justify-between items-center w-1/2 sm:mx-8">
                        <a className="hover:underline hover:text-blue-500 text-tertiary flex items-center"
                            href="mailto:contact@bds-telecom-paris.fr"
                            style={{
                                color: isHovered == 2 ? '#2a0606' : 'white'
                            }}
                            onMouseEnter={(e) => {
                                setIsHovered(2);
                            }}
                            onMouseLeave={(e) => {
                                setIsHovered(0);
                            }}
                        >
                            <svg className="w-12 h-12 fill-current mt-4 mr-1" viewBox="0 0 24 24"><FaMailBulk /></svg>
                            Email
                        </a>
                    </li>
                    <li className="flex justify-between items-center w-1/2 sm:mx-8">
                        <a className="hover:underline hover:text-blue-500 text-tertiary flex items-center"
                            href="https://www.facebook.com/BdsTelecomParis"
                            style={{
                                color: isHovered == 3 ? '#2a0606' : 'white'
                            }}
                            onMouseEnter={(e) => {
                                setIsHovered(3);
                            }}
                            onMouseLeave={(e) => {
                                setIsHovered(0);
                            }}
                        >
                            <svg className="w-12 h-12 fill-current mt-4 mr-1" viewBox="0 0 24 24"><FaFacebookF /></svg>
                            Facebook
                        </a>
                    </li>
                </ul>
                <p className="text-tertiary sm:text-xl text-xs sm:w-1/5 text-right mt-3">
                    BDS Télécom Paris © 2024
                </p>
            </header>
        </div>
    )
}