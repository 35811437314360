
interface ScreenProps{
    isFr:boolean;
    switchFrEn: ()=>void;
}

export const Cotiser : React.FC<ScreenProps> = ({isFr, switchFrEn})=>{
    return (
        <body>
           <div className="bg-white p-6 shadow-md rounded-md mb-8 mt-8">
                        <h3 className="text-xl font-bold mb-4 text-justify">{isFr ? `Devenir Adhérent`: `Become a Member`}</h3>
                            <p className="text-gray-700 text-lg mb-6 text-justify">
                            {isFr ? ` Le mois de septembre est l'occasion pour toi de pratiquer ou d'essayer n'importe quel sport du BDS (bureau des sports). Tu n'as pas à t'inscrire, tu peux y aller directement et faire le sport de ton choix alors n'hésite pas !
`: `The month of September is an opportunity for you to practice or try any sport offered by the BDS (sports office). You don't need to register, you can go directly and do the sport of your choice, so don't hesitate!`}
                           
                            </p><p className="text-gray-700 text-lg mb-4 text-justify"> {isFr ? ` À partir du mois d'octobre, il faudra cependant cotiser au BDS pour pouvoir continuer à participer aux sports et event du BDS. Tu peux t'inscrire à n'importe quel moment de l'année et ta cotisation te permet d'avoir des réductions sur certains événements sportifs tels que des places pour voir des matchs professionnels ! 
                           `:` Starting from the month of October, it will be necessary to contribute to the BDS in order to continue participating in BDS sports and events. You can register at any time during the year, and your contribution entitles you to discounts on certain sports events, such as tickets to professional matches!`}
                            </p><p className="text-gray-700 text-lg mb-4 text-justify">{isFr ? `Tu peux cotiser pour 1 an (80 euros) ou pour 3 ans (150 euros).`:`You can contribute for 1 year (80 euros) or for 3 years (150 euros).`}
                            </p>
                            <a href={'https://collecte.io/cotisations-bds-2024-2025-7518521/fr'} className=" ms:ml-80 bg-primary hover:bg-secondary text-white py-2 px-4 rounded-md">{isFr ? `Lien Lydia`:`Lydia Link`}</a>
                           
            </div>
        </body>
    )

}